import React from "react"

const Footer = () => (
  <footer>
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          Designed and developed by{" "}
          <a href="https://TrueMoein.ir" target="_blank" rel="noopener noreferrer">
            M.Alizadeh
          </a>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
