// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-gallery-page-template-js": () => import("../src/templates/gallery-page-template.js" /* webpackChunkName: "component---src-templates-gallery-page-template-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-index-js": () => import("../src/pages/gallery/index.js" /* webpackChunkName: "component---src-pages-gallery-index-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resume-js": () => import("../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */)
}

