import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import {
  useIntl,
  Link,
  FormattedMessage,
  changeLocale,
  navigate,
} from "gatsby-plugin-intl"
import Img from "gatsby-image"

import iran from "./../assets/img/iran.png"
import uk from "./../assets/img/uk.png"
import menu from "./../assets/img/menu.png"

const Header = () => {
  const intl = useIntl()
  const [showMenu, setShowMenu] = useState(false)

  const { en, fa } = useStaticQuery(graphql`
    query {
      en: contentfulSetting(node_locale: { eq: "en-US" }) {
        name
        avatar {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSetWebp
            srcSet
            srcWebp
          }
        }
        shortBiography
      }
      fa: contentfulSetting(node_locale: { eq: "fa" }) {
        name
        shortBiography
      }
    }
  `)

  const changeLanguage = lang => () => {
    changeLocale(lang)
    document.body.dir = lang === "fa" ? "rtl" : "ltr"
  }

  const navigateMobileMenu = to => () => {
    if (showMenu) {
      navigate(to);
      setShowMenu(false);
    }
  }

  useEffect(() => {
    document.body.dir = intl.locale === "fa" ? "rtl" : "ltr"
  }, [])

  return (
    <header>
      <div className={`container ${intl.locale === "fa" ? "rtl" : "ltr"}`}>
        {showMenu && (
          <div className="menu-background" onClick={() => setShowMenu(false)} />
        )}
        <div className="row">
          <div className="col-12 avatar">
            <div className="menu-icon">
              <img
                className="d-block d-sm-none"
                src={menu}
                alt="Menu"
                onClick={() => setShowMenu(true)}
              />
            </div>
            <Img
              fluid={en.avatar.fluid}
              alt={intl.locale === "fa" ? fa.name : en.name}
            />
            <h1>{intl.locale === "fa" ? fa.name : en.name}</h1>
            <h4>
              {intl.locale === "fa" ? fa.shortBiography : en.shortBiography}
            </h4>
          </div>

          <div
            className={`col-sm-12 d-none d-sm-block site-menu ${
              showMenu ? "showMobileMenu" : "hideMobileMenu"
            }`}
          >
            <nav>
              <ul>
                <li onClick={navigateMobileMenu("/")}>
                  <Link to="/">
                    <FormattedMessage id="home" />
                  </Link>
                </li>
                <li onClick={navigateMobileMenu("/gallery")}>
                  <Link to="/gallery">
                    <FormattedMessage id="gallery" />
                  </Link>
                </li>
                <li onClick={navigateMobileMenu("/resume")}>
                  <Link to="/resume">
                    <FormattedMessage id="resume" />
                  </Link>
                </li>
                <li onClick={navigateMobileMenu("/contact")}>
                  <Link to="/contact">
                    <FormattedMessage id="contact" />
                  </Link>
                </li>
                <li>
                  {intl.locale === "en" ? (
                    <>
                      <img
                        className={intl.locale === "fa" ? "active" : undefined}
                        src={iran}
                        alt={intl.formatMessage({ id: "persian" })}
                        onClick={changeLanguage("fa")}
                        title={intl.formatMessage({ id: "persian" })}
                      />
                      {showMenu && (
                        <span onClick={changeLanguage("fa")}>
                          Change language
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      <img
                        className={intl.locale === "en" ? "active" : undefined}
                        src={uk}
                        alt={intl.formatMessage({ id: "english" })}
                        onClick={changeLanguage("en")}
                        title={intl.formatMessage({ id: "english" })}
                      />
                      {showMenu && (
                        <span onClick={changeLanguage("en")}>تغییر زبان</span>
                      )}
                    </>
                  )}
                </li>
              </ul>
            </nav>
          </div>
          <hr />
        </div>
      </div>
    </header>
  )
}

export default Header
