import React from "react"
import PropTypes from "prop-types"
import Header from "../components/Header"

import "bootstrap/dist/css/bootstrap-reboot.min.css"
import "bootstrap/dist/css/bootstrap-grid.min.css"
import "../assets/css/public.css"
import Footer from "../components/Footer"

const Layout = ({ children }) => (
  <>
    <Header />
    <main>
      {children}
    </main>
    <Footer />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
